import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import dataProvider from "../../../../data/dataProvider";
import { is_empty } from "../../../../utils/validations";
import api from "../../../../data/APIs";
import ExlyTable from "common/Components/ExlyTable";
import { columnConfig, filterConfig, tableChipsFormatter } from "./tableConfig";
import { MobileLinkField } from "./components/CustomColumns/CustomColumns";
import TrafficAnalysisStats from "./components/TrafficAnalysisStats";
import {
  Button,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import TrackingSummaryModal from "./components/TrackingSummaryModal";
import TrackingLinkForm from "./components/TrackingLinkForm";
import constants from "constants/constants";
import jsonExport from "jsonexport/dist";
import { downloadCSV, useListController, useRefresh } from "react-admin";
import {
  orgPermissions,
  RESOURCE_KEYS,
} from "../../../../utils/OrgPermissions";
import { useNotifications, useToggleState } from "utils/hooks";
import { getSalePageUrl } from "features/SalesPages/utils/getSalePageUrl";
import {
  SALES_PAGE_STATUSES_TO_SHOW,
  TRAFFIC_ANALYSIS_LEARN_MORE_HREFS,
  TRAFFIC_LINK_REDIRECT_TYPE,
} from "./affiliates.constants";
import { fetchStatusAndListingBasedSalesPgs } from "ui/pages/CustomCodes/Codes.api.helpers";
import { logError } from "utils/error";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { useShareListingPopup } from "features/Listings/modules/ShareListingPopup/utils/useShareListingPopup";
import { ShareListingPopupLazyComponent } from "features/Listings/constants/Listings.lazy";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";

export const Affiliates = (props) => {
  const [flow, setFlow] = useState("");
  const [listingData, setlistingData] = useState("");
  const [plans_data, set_plans_data] = useState(null);
  const [salesPageData, setSalesPgData] = useState(null);
  const [affiliateLink, setaffiliateLink] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [affiliateData, setAffiliateData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmissionDisabled, disableSubmission, enableSubmission] =
    useToggleState(false);
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const { filterValues, loaded, data } = useListController(props);
  const isEmpty = is_empty(data) && loaded && is_empty(filterValues);
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.TRAFFIC_ANALYSIS
  );
  const refresh = useRefresh();
  const { openShareListingPopup, shareListingPopupOpen } =
    useShareListingPopup();

  const { pushNotification } = useNotifications();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.TRAFFIC_ANALYSIS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const sub_domain = !is_empty(localStorage.getItem("auth"))
    ? JSON.parse(localStorage.getItem("auth")).sub_domain
    : null;

  React.useEffect(() => {
    (async () => {
      try {
        const affiliatedropdownData = await dataProvider.custom_request(
          api.get_affiliate_dropdown_data,
          "GET"
        );
        if (affiliatedropdownData.status === 200) {
          setlistingData(affiliatedropdownData.data.affiliates);
        }

        const userInfo = JSON.parse(localStorage.getItem("auth"));
        if (!is_empty(sub_domain)) {
          const getPlans = await dataProvider.custom_request(
            `${api.get_plans}/${userInfo?.sub_domain}`,
            `GET`
          );

          if (getPlans.status === 200) {
            set_plans_data([...getPlans.data.plans]);
          }
        }
        const statusString = SALES_PAGE_STATUSES_TO_SHOW.join(",");
        const { sales_pages } = await fetchStatusAndListingBasedSalesPgs({
          status: statusString,
        });
        if (!is_empty(sales_pages)) setSalesPgData(sales_pages);
      } catch (error) {
        logError({
          error,
          occuredAt: "useEffect in src/ui/pages/marketing/affiliates/index.js",
          when: "calling listing apis for Traffic affiliates listing",
        });
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!showSummary) setAffiliateData({});
  }, [showSummary]);

  const handleTransactions = async (record) => {
    if (record.bookings) {
      window.location.href = `${window.location.origin}/#/${api.transaction_list}?utm_info__affiliate=${record.affiliate_code}`;
    }
  };

  const handleViewBookingsClick = (record) => handleTransactions(record);

  const trackingData = async (affiliateCode) => {
    setShowSummary(true);

    try {
      let data = await dataProvider.custom_request(
        `host/affliate/details/${affiliateCode}`
      );
      if (data.status === 200) {
        setAffiliateData(data.data);
      }
    } catch (err) {
      setShowSummary(false);
      pushNotification(err.message, {
        variant: "outlined",
        color: "coral_red",
      });
    }
  };

  const handleTrackingSummaryClick = (record) =>
    trackingData(record.affiliate_code);

  const handleShare = (record, source) => {
    setFlow("");
    setaffiliateLink(record[source]);
    setSelectedRecord(record);
    openShareListingPopup();
  };
  const hostSiteURL = React.useMemo(() => getCreatorHostsiteURL()?.url, []); //value will be fetched before this component load

  const handleSubmit = async (state) => {
    const payload = {
      name: state.affiliateName,
      redirect_type: state.target,
    };
    if (is_empty(sub_domain)) {
      return console.error("sub_domain missing");
    }

    if (state.target === TRAFFIC_LINK_REDIRECT_TYPE.HOME_PAGE.value) {
      payload.target = "Home Page";
      payload.url = hostSiteURL;
    } else if (state.target === TRAFFIC_LINK_REDIRECT_TYPE.LISTING.value) {
      const selectedListing = (listingData || []).find(
        (item) => item.title === state.url
      );
      if (selectedListing) {
        payload.url = selectedListing.url;
        payload.target = selectedListing.title;
        payload.redirect_uuid = selectedListing.uuid;
      }
    } else if (state.target === TRAFFIC_LINK_REDIRECT_TYPE.PLAN.value) {
      const selectedPlan = (plans_data || []).find(
        (item) => item.uuid === state.url
      );
      if (selectedPlan) {
        payload.url = `${hostSiteURL}/${selectedPlan.parent_listing_uuid}?plan_id=${selectedPlan.uuid}`;
        payload.target = selectedPlan.title;
        payload.redirect_uuid = selectedPlan.uuid;
      }
    } else if (state.target === TRAFFIC_LINK_REDIRECT_TYPE.SALES_PAGE.value) {
      const selectedSalepage = state.url;
      payload.url = getSalePageUrl({ salePageDetail: selectedSalepage });

      payload.target = selectedSalepage.title;
      payload.redirect_uuid = selectedSalepage.uuid;
    }

    if (!payload.name || !payload.url) {
      alert("Please fill all mandatory fields");
      return;
    }

    try {
      disableSubmission();
      const creatAffiliate = await dataProvider.custom_request(
        "host/affliate/submit",
        "POST",
        payload
      );

      if (creatAffiliate.status === 200) {
        setFlow("");
        setaffiliateLink(creatAffiliate.data.affiliate_url);
        setSelectedRecord(payload);
        openShareListingPopup();
        setShowForm(false);
      }
      enableSubmission();
    } catch (err) {
      enableSubmission();
      console.error("err", err);
    }
  };

  const exporter = (data) => {
    dataProvider.postAnalyticalEvent("Affiliates", "export", {
      flow: "Affiliates",
      funnel: "Affiliates",
      ui_component: "Text Button",
      identifier: "EXPORT",
    });
    const dataForExport = data.map((data) => {
      let return_data = {
        "Affiliate Name": data.name,
        Target: data.target,
        "Affiliate Link": data.url,
        Leads: `${data.leads || 0} leads`,
        Bookings: `${data.bookings || 0} bookings`,
      };
      return return_data;
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "Affiliate Name",
          "Target",
          "Affiliate Link",
          "Leads",
          "Bookings",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Affilates"); // download as 'Affilates.csv` file
      }
    );
  };

  const secondaryComponent = isDesktop && (
    <div className="d-flex">
      <LearnMoreCta
        href={TRAFFIC_ANALYSIS_LEARN_MORE_HREFS.TRAFFIC_ANALYSIS}
        className="mr-3"
      />
      {HAS_WRITE_ACCESS && !isEmpty && (
        <Button
          style={{ padding: "11px 20px" }}
          color="primary"
          onClick={() => setShowForm(true)}
        >
          Create Tracking Link
        </Button>
      )}
    </div>
  );

  const footerProps = {
    primarybtn: "Create Tracking Link",
    primaryClick: () => setShowForm(true),
  };

  return (
    <div className="component-wrapper">
      <ExlyTable
        tableTitle="Traffic Analysis"
        forceEmptyState={isEmpty}
        ra_props={{
          ...props,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        noExportButton={!orgPermissions.canExport()}
        columnConfig={columnConfig(
          handleTrackingSummaryClick,
          handleViewBookingsClick,
          handleShare
        )}
        tableFilters={filterConfig}
        statsComponent={TrafficAnalysisStats}
        layoutProps={{
          secondaryComponent,
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
          learnMoreHref: TRAFFIC_ANALYSIS_LEARN_MORE_HREFS.TRAFFIC_ANALYSIS,
        }}
        footerProps={footerProps}
        tableChipsFormatter={tableChipsFormatter}
        hasMobileFooter={HAS_WRITE_ACCESS}
        primaryKey="id"
        uniqueRowIdFieldName="id"
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) => (
          <MobileLinkField
            record={record}
            source="url"
            onShareClick={() => handleShare(record, "url")}
          />
        )}
        customPrimaryAction={{
          label: "Actions",
          onClick: (
            row,
            rows,
            setDrawerConfig,
            setActionsDrawerConfig,
            rowIndex
          ) =>
            setDrawerConfig({
              drawerActionType: "view",
              open: true,
              row,
              rowIndex,
            }),
        }}
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "Create a tracking link",
          description:
            "Create a unique link that directs to your Homepage or Offering pages. View the video to learn more.",
          videoSrc: TRAFFIC_ANALYSIS_LEARN_MORE_HREFS.TRAFFIC_ANALYSIS_VIDEO,
          playerProps: {
            playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
          },
          primaryCtaProps: {
            title: "Create a tracking link",
            onClick: () => setShowForm(true),
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: TRAFFIC_ANALYSIS_LEARN_MORE_HREFS.TRAFFIC_ANALYSIS,
          },
          className: "mt-4",
        }}
      />
      {/* comes from traffic analysis */}
      {flow !== "trackingSummary" && (
        <LazyLoadWrapper loadingCondition={shareListingPopupOpen}>
          <ShareListingPopupLazyComponent
            onClose={refresh}
            shareLink={affiliateLink}
            offeringId={
              selectedRecord?.redirect_type ===
              TRAFFIC_LINK_REDIRECT_TYPE.LISTING.value
                ? selectedRecord?.redirect_uuid
                : ""
            }
            offeringData={{
              cover_image: constants.default_schedule_image,
              metadata: {
                share_url: affiliateLink,
              },
            }}
            headerImg="reward.png"
            headerTitle="Share it with your audience!"
            copyListingPageCTAText="Copy Link"
            showCopyIcon
            hideOfferingToast
            hideNextDetails
          />
        </LazyLoadWrapper>
      )}
      <ThemeProvider theme={creatorToolTheme}>
        <TrackingSummaryModal
          open={showSummary}
          onClose={() => setShowSummary(false)}
          onClickClose={() => setShowSummary(false)}
          affiliateData={affiliateData}
        />
        {showForm && (
          <TrackingLinkForm
            open={showForm}
            onClose={() => setShowForm(false)}
            onSubmit={handleSubmit}
            plansData={plans_data}
            listingData={listingData}
            isSubmissionDisabled={isSubmissionDisabled}
            salesPageData={salesPageData}
          />
        )}
      </ThemeProvider>
    </div>
  );
};
